.ui-panel-inner {
    background: rgba( 255, 255, 255, 0.08 );
    box-shadow: 0 3px 6px 0 rgba( 31, 38, 135, 0.10);
    backdrop-filter: blur( 4px );
    -webkit-backdrop-filter: blur( 4px );
    border-radius: 20px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    -webkit-transition: 0.4s ease-in-out;
    -moz-transition: 0.4s ease-in-out;
    -o-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    cursor: pointer;

  }
.ui-panel-inner:hover {
  background: rgba( 255, 255, 255, 0.38 );
}
