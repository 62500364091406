body {
  background: linear-gradient(270deg, #a770ef, #cf8bf3, #fdb99b, #de9bfd, #9b9dfd, #90bedd, #8edcee);
  background-size: 1400% 1400%;

  -webkit-animation: AnimationName 120s ease infinite;
  -moz-animation: AnimationName 120s ease infinite;
  animation: AnimationName 120s ease infinite;
  caret-color: rgba(0,0,0,0);

  overflow-x: hidden;
  height: 100%;

  font-family: 'Quicksand', sans-serif !important;
  font-weight: 500 !important;
  overflow-anchor: none;
}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}

.img-holder {
/* -webkit-box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.25); 
box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.25); */
  border-radius: 10px;
  opacity: 0.95;
  max-width: 90%;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}

.ui-text-shadow {
  text-shadow: 0px 3px 4px rgba(255,255,255,0.07);

}
.white {
  color: #FFF;
}
.header-text {
  font-weight: 600;
  font-size: 22px;
  color: rgba( 255, 255, 255, 0.85 );
  padding-bottom: 8px;
}
.max-height {
  height: 100%;
}
.social-icon {
  font-size: 18px;
  color: black;
  opacity: 0.9;
  text-decoration: none;
}
.social-icon:hover {
  font-size: 18px;
  text-shadow: 0px 3px 4px rgba(0,0,0,0.77);
  color: white;
}