.year {
    border: 0px solid black;
    font-weight: 300;
}

.title {
    font-weight: 600;
}
.place {
    font-weight: 400;
    font-size: 14px;
}
.quote {
    font-size: 18px;
    font-weight: 600;
}