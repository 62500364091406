.circle
{
    left: -5%;  
    top: 20%;
    position: absolute;
    width: 690px;
    height: 690px;
    border-radius: 50%;
    -webkit-filter:blur(95px);
    animation-name: glow;
    animation-duration: 15s;
    animation-timing-function:  ease-in-out; 
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
  overflow: hidden;
}
@keyframes glow {
  0% {
    background-color: rgba(100, 100, 100,0.1);
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
    transform: skew(15deg, 15deg);
    background-color: rgba(255, 255, 255,0.1);
  }
  100% {
    transform: scale(1.2);
    transform: skew(-15deg, -15deg);
    background-color: rgba(100, 100, 100,0.1);
  }
}
@keyframes glow-inverse {
  0% {
    background-color: rgba(255, 255, 255, 0.25);
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
    background-color: rgba(255, 255, 255, 0.28);
  }
  100% {
    transform: scale(1.5);
    background-color: rgba(255, 255, 255, 0.25);
  }
}
.circle-two
{
    left: 50%;  
    top: 50%;
    position: absolute;
    width: 650px;
    height: 650px;
    border-radius: 50%;
    background-color: rgba(255,255,255,0.95);
    -webkit-filter:blur(105px);
      animation-name: glow;
    animation-duration: 12s;
    animation-timing-function:  ease-in-out; 
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
  overflow: hidden;
}

.circle-three
{
    left: 50%;  
    top: -50%;
    position: absolute;
    width: 650px;
    height: 650px;
    border-radius: 50%;
    background-color: rgba(245, 179, 172, 0.25);
    -webkit-filter:blur(95px);
        animation-name: glow-inverse;
    animation-duration: 25s;
    animation-timing-function:  ease-in-out; 
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
  overflow: hidden;
}